import React, { useState } from "react";

export default function TextForm(props) {
  // uppercase function
  const handleUpClick = () => {
    console.log("Uppercase was clicked" + text);
    let newText = text.toUpperCase();
    setText(newText);
    props.showAlert("Covnertd to upper case", "success");
  };
  //  lowerCse function
  const handleLowerrClick = () => {
    let newText = text.toLowerCase();
    setText(newText);
    props.showAlert("Covnertd to lower case", "success");
  };

  // clear function

  const handleClClick = () => {
    setText("");
    props.showAlert("Text box is Cleared", "success");
  };

  //   romove space function
  const handleRemove = () => {
    let newText = text.split(/[ ]+/);
    setText(newText.join(""));
    props.showAlert("Extra spaces are Removed SuccessFully", "success");
  };

  //  copy function
  const handleCopy = (event) => {
    var text = document.getElementById("exampleFormControlTextarea1");
    text.select();
    navigator.clipboard.writeText(text.value);
    props.showAlert("Text copied SuccessFully", "success");
  };

  const [text, setText] = useState("");
  return (
    <>
      <div
        className="container my-5 "
        style={{ backgroundColor: props.mode === "dark" ? "light" : "dark" }}
      >
        <h2>{props.heading}</h2>
        <div className="mb-3">
          <textarea
            className="form-control"
            id="exampleFormControlTextarea1"
            onChange={(e) => setText(e.target.value)}
            value={text}
            rows="8"
            style={{ backgroundColor: "#BDCDD6" }}
          ></textarea>
        </div>
        <button className="btn btn-primary mx-2 my-3" onClick={handleUpClick}>
          Convert to uppercase
        </button>
        <button
          className="btn btn-primary mx-2 my-3"
          onClick={handleLowerrClick}
        >
          Convert to lovercase
        </button>
        <button className="btn btn-primary mx-2 my-3" onClick={handleClClick}>
          Clear text
        </button>
        <button className="btn btn-primary mx-2 my-3" onClick={handleRemove}>
          Remove Space
        </button>
        <button className="btn btn-primary mx-2 my-3" onClick={handleCopy}>
          Copy text{" "}
        </button>
      </div>
      <div
        className="container my-3"
        style={{ backgroundColor: props.mode === "light" ? "dark" : "light" }}
      >
        <h2>Your text summary</h2>
        <p>
          {
            text.split(" ").filter((element) => {
              return element.length !== 0;
            }).length
          }{" "}
          word, {text.length} charcters
        </p>
        <p>
          {0.008 *
            text.split(" ").filter((element) => {
              return element.length !== 0;
            }).length}{" "}
          minutes read
        </p>
        <p>Number of sentence : {text.split(".").length - 1} </p>
        <h2>Preview</h2>
        <p>{text.length > 0 ? text : "Enetr the text to preview"}</p>
      </div>
    </>
  );
}
