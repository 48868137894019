import React from "react";
import "./Alert.css";

export default function Alert(props) {
  // convert first latter of success in capital
  const captilize = (word) => {
    const lower = word.toLowerCase();
    return lower.charAt(0).toUpperCase() + lower.slice(1);
  };
  return (
    <div className="alert">
      {props.alert && (
        <div
          className={`alert alert-${props.alert.type} lert-dismissible fade show`}
          role="alert"
        >
          <strong>{captilize(props.alert.type)}</strong>:{props.alert.msg}
        </div>
      )}
    </div>
  );
}
